@charset "utf-8";

:root {
  --base-width: 1280px;
  --narrow-width: 1110px;

  --color00: 51, 51, 51;     /* #333; */
  --color01: 52, 113, 214;   /* #3471d6; */
  --color02: 216, 237, 248;  /* #d8edf8; */
  --color03: 233, 108, 103;  /* #e96c67; */
  --color04: 66, 197, 144;   /* #42c590; */
  --color05: 235, 99, 0;     /* #eb6300; */
  --color06: 149, 172, 76;   /* #95AC4C; */
  --color07: 253, 252, 231;  /* #fdfce7; */
  --color08: 255, 255, 255;  /* #fff */
  --color09: 212, 244, 229;  /* #d4f4e5 */
  --color10: 255, 50, 77;    /* #ff324d */
}

html {
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: -webkit-fill-available;
}

body {
  font-family: 'メイリオ', 'Meiryo', 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-feature-settings: "palt" 1;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding-top: calc(var(--gh-H) + 1rem);
  color: rgba(var(--color00),1);
  background: rgba(var(--color08),1);
}
@media ( min-width: 834px ) {
  body {
    padding-top: 0;
  }
}
a:hover {
  opacity: 0.6;
  transition: opacity 0.35s ease;
}

.sec {
  margin: 0 auto 1rem auto;
  width: 100%;
  max-width: var(--base-width);
}
.sec:not(:first-of-type) {
  padding-top: 2rem;
}
.sec:not([class*="row"])::after {
  content: '';
  display: table;
  clear: both;
}

@media ( min-width: 641px ) {
  .sec > .sec {
    padding-top: 0;
  }
}
body > .sec {
  padding: 0 4vw;
}
@media ( min-width: 1281px ) {
  body > .sec {
    padding: 0;
  }
}

.sub .sec {
  padding: 0;
  margin-bottom: 1.5rem;
  text-align: left;
}
.sub-inner {
  display: block;
}
.sub-inner > * {
  order: 100;
}
.sub-inner .order {
  width: 100%;
}
@media ( min-width: 641px ) {
  .sub-inner {
    position: static;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .sub-inner > * {
    align-self: flex-start;
  }
  .sub-inner ._half {
    width: calc( 100% / 2 - 2% );
  }

  .withInner > .column-wrap .column-wrap .sub-inner {
    display: block;
  }
  .withInner > .column-wrap .column-wrap .sub-inner > *:nth-child(odd) {
    float: left;
  }
  .withInner > .column-wrap .column-wrap .sub-inner > *:nth-child(even),
  .withInner > .column-wrap .column-wrap .sub-inner > *:nth-child(odd):nth-of-type(3) {
    float: right;
  }
}
@media ( min-width: 1281px ) {
  .sub-inner {
    position: sticky;
    bottom: 0;
    display: block;
  }
  .sub-inner ._half {
    width: 100%;
  }
}

.sub .sec > a:only-child:has(img:only-child) {
  display: block;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
}

.sec-headline {
  font-size: min(5vw, 18px);
  font-weight: 700;
  padding-bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub .sec > .sec-headline {
  padding: 1.5rem 0 1rem 0;
}
.sec-headline > a {
  color: rgba(var(--color01),1);
  font-size: 75%;
}



/* attach for ".sec-headline"
____________________________________________________ */
.bo {
  padding-top: 2rem;
}
.c-set00 { color: rgba(var(--color00),1); }
.c-set01 { color: rgba(var(--color01),1); }
.c-set02 { color: rgba(var(--color02),1); }
.c-set03 { color: rgba(var(--color03),1); }
.c-set04 { color: rgba(var(--color04),1); }
.c-set05 { color: rgba(var(--color05),1); }
.c-set00.bo { border-top: 1px solid rgba(var(--color00),0.5); }
.c-set01.bo { border-top: 1px solid rgba(var(--color01),1); }
.c-set02.bo { border-top: 1px solid rgba(var(--color02),1); }
.c-set03.bo { border-top: 1px solid rgba(var(--color03),1); }
.c-set04.bo { border-top: 1px solid rgba(var(--color04),1); }
.c-set05.bo { border-top: 1px solid rgba(var(--color05),1); }


/* base tab
____________________________________________________ */
.sec-headline.basetab {
  margin-bottom: 0;
  color: rgba(var(--color01),1);
  background: rgba(var(--color08),1);
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid rgba(var(--color01),1);
  border-bottom: none;
  padding: 0.65rem 1rem 0.5rem 1rem;
  border-radius: 5px 5px 0 0;
}
@media ( min-width: 641px ) {
  .sec-headline.basetab {
    padding: .75em 3em .5em 3em;
  }
}


/* PR AREA
____________________________________________________ */
.ad-wrap {
  width: 100%;
  overflow: hidden;
  display: block;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 1;
}
.article-list + .ad-wrap {
  margin-top: 2rem;
}
.ad-wrap + .ad-wrap {
  margin-top: 1rem;
}
.ad-wrap:empty {
  padding: 0;
}
.ad-wrap > .ad-wrap-inner {
  width: auto;
  margin: auto;
  display: block;
  padding: 0 0 1rem 0;
}
.sub .ad-wrap .ad-wrap-inner {
  max-width: 100%;
  height: auto;
  padding: 0 0 1rem 0;
}
@media ( min-width: 843px ) {
  .ad-wrap {
    flex-direction: row;
    display: flex;
  }
}
@media ( min-width: 1281px ) {
  .ad-wrap {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.overhead {
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 100;
}
.overhead > * {
  padding: 0;
}
@media (orientation: landscape) and (max-width: 834px ){
  .overhead {
    display: none;
  }
}


/* each icos
____________________________________________________ */
[class*="ico-"] {
  display: flex;
  justify-content: center;
  align-items: center;
}
[class*="ico-"]::before {
  content: '';
  width: min(9vw, 34px);
  height: min(9vw, 34px);
  display: inline-flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.ico-newspaper::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d5;}</style></defs><path class="cls-1" d="M30.31,9.91c-.33-.17-.73-.15-1.03,.06l-1.84,1.27v-3.35c0-.4-.24-.77-.61-.92-.37-.15-.8-.07-1.09,.21l-1.33,1.31-1.33-1.31c-.39-.38-1.01-.38-1.4,0l-1.33,1.31-1.33-1.31c-.39-.38-1.02-.38-1.4,0l-1.33,1.31-1.32-1.31c-.39-.38-1.02-.38-1.41,0l-1.32,1.31-1.32-1.31c-.39-.38-1.02-.38-1.4,0l-1.32,1.31-1.32-1.31c-.29-.28-.71-.36-1.09-.21-.37,.16-.61,.52-.61,.92V26.1c0,1.65,1.35,3,3,3H27.84c1.65,0,3-1.35,3-3V10.8c0-.37-.21-.71-.54-.89Zm-1.46,16.19c0,.55-.45,1-1,1H8.16c-.55,0-1-.45-1-1V10.29l.32,.32c.39,.38,1.02,.38,1.4,0l1.32-1.31,1.32,1.31c.39,.38,1.02,.38,1.41,0l1.32-1.31,1.32,1.31c.39,.38,1.02,.38,1.4,0l1.33-1.31,1.33,1.31c.39,.38,1.02,.38,1.4,0l1.33-1.31,1.33,1.31c.39,.38,1.01,.38,1.4,0l.33-.33v13.16c0,.55,.45,1,1,1s1-.45,1-1V13.67l1.41-.97v13.41Z"/><rect class="cls-1" x="10.3" y="13.4" width="5" height="4"/><rect class="cls-1" x="10.3" y="19.4" width="12" height="1"/><rect class="cls-1" x="10.3" y="22.4" width="12" height="1"/><rect class="cls-1" x="17.3" y="16.4" width="5" height="1"/><rect class="cls-1" x="17.3" y="13.4" width="5" height="1"/></svg>');
}
.ico-mynews::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d5;}</style></defs><path class="cls-1" d="M22.37,13.57c0-2.41-1.96-4.37-4.37-4.37s-4.37,1.96-4.37,4.37,1.96,4.37,4.37,4.37,4.37-1.96,4.37-4.37Zm-7.34,0c0-1.64,1.33-2.97,2.97-2.97s2.97,1.33,2.97,2.97-1.33,2.97-2.97,2.97-2.97-1.33-2.97-2.97Z"/><path class="cls-1" d="M11.23,27.47c0,1.98,2.93,3.48,6.82,3.48s6.82-1.5,6.82-3.48c0-1.21-1.06-2.24-2.91-2.87,4.87-6.33,4.57-11.02,4.57-11.02,0-4.7-3.81-8.52-8.52-8.52s-8.52,3.81-8.52,8.52c0,0-.36,4.87,4.52,11.07-1.77,.64-2.77,1.65-2.77,2.83Zm.4-13.73v-.08s0-.08,0-.08c0-3.51,2.86-6.36,6.36-6.36s6.36,2.86,6.36,6.36v.04s0,.07,0,.07c0,.05,.13,5.41-6.37,12.21-6.58-6.56-6.38-11.96-6.37-12.16Zm4.9,13.73c.4,.4,.95,.6,1.49,.6s1.07-.2,1.46-.6c.56-.58,1.06-1.15,1.54-1.72,.02,0,.03,.02,.04,.02,1.66,.47,2.4,1.19,2.4,1.69,0,.87-2.06,2.08-5.42,2.08s-5.42-1.21-5.42-2.08c0-.5,.73-1.21,2.34-1.67,.48,.55,.99,1.11,1.56,1.67Z"/></svg>');
}
.mynews-wrap a.ico-mynews::before {
  position: absolute;
  content: '';
  width: 15%;
  height: min(10vw, 42px);
  top: 50%;
  transform: translateY(-50%);
  right: calc( 100% + min(4vw, 7px) );
  background-position: center;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23fff;}</style></defs><path class="cls-1" d="M22.37,13.57c0-2.41-1.96-4.37-4.37-4.37s-4.37,1.96-4.37,4.37,1.96,4.37,4.37,4.37,4.37-1.96,4.37-4.37Zm-7.34,0c0-1.64,1.33-2.97,2.97-2.97s2.97,1.33,2.97,2.97-1.33,2.97-2.97,2.97-2.97-1.33-2.97-2.97Z"/><path class="cls-1" d="M11.23,27.47c0,1.98,2.93,3.48,6.82,3.48s6.82-1.5,6.82-3.48c0-1.21-1.06-2.24-2.91-2.87,4.87-6.33,4.57-11.02,4.57-11.02,0-4.7-3.81-8.52-8.52-8.52s-8.52,3.81-8.52,8.52c0,0-.36,4.87,4.52,11.07-1.77,.64-2.77,1.65-2.77,2.83Zm.4-13.73v-.08s0-.08,0-.08c0-3.51,2.86-6.36,6.36-6.36s6.36,2.86,6.36,6.36v.04s0,.07,0,.07c0,.05,.13,5.41-6.37,12.21-6.58-6.56-6.38-11.96-6.37-12.16Zm4.9,13.73c.4,.4,.95,.6,1.49,.6s1.07-.2,1.46-.6c.56-.58,1.06-1.15,1.54-1.72,.02,0,.03,.02,.04,.02,1.66,.47,2.4,1.19,2.4,1.69,0,.87-2.06,2.08-5.42,2.08s-5.42-1.21-5.42-2.08c0-.5,.73-1.21,2.34-1.67,.48,.55,.99,1.11,1.56,1.67Z"/></svg>');
}
.ico-go::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d5;}</style></defs><path class="cls-1" d="M27.03,10.32h-5.52v-2.55c0-.55-.45-1-1-1h-5.02c-.55,0-1,.45-1,1v2.55h-5.52c-1.66,0-3.01,1.34-3.01,2.99v12.94c0,1.65,1.35,2.99,3.01,2.99H27.03c1.66,0,3.01-1.34,3.01-2.99V13.31c0-1.65-1.35-2.99-3.01-2.99Zm-7.52-1.56v1.56h-3.01v-1.56h3.01ZM7.97,13.31c0-.55,.45-1,1-1h1.92c-.16,1.53-1.38,2.74-2.92,2.9v-1.9Zm1,13.93c-.55,0-1-.45-1-1v-1.79c1.51,.15,2.71,1.31,2.91,2.78h-1.91Zm-1-3.78v-7.25c2.1-.16,3.76-1.81,3.93-3.9h.5v14.93h-.51c-.22-2.03-1.86-3.62-3.92-3.78Zm6.44,3.78V12.31h7.19v14.93h-7.19Zm13.63-1c0,.55-.45,1-1,1h-1.79c.2-1.44,1.34-2.57,2.79-2.77v1.78Zm0-2.77c-2,.21-3.59,1.78-3.8,3.77h-.63V12.31h.62c.16,2.04,1.77,3.67,3.81,3.88v7.28Zm0-8.27c-1.49-.2-2.65-1.39-2.81-2.89h1.8c.55,0,1,.45,1,1v1.89Z"/></svg>');
}
.ico-search::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d5;}</style></defs><path class="cls-1" d="M26.91,24.09l-3.53-3.53c.7-1.19,1.12-2.57,1.12-4.06,0-4.41-3.59-8-8-8s-8,3.59-8,8,3.59,8,8,8c1.48,0,2.86-.41,4.06-1.12l3.53,3.53c.39,.39,.9,.59,1.41,.59s1.02-.2,1.41-.59c.78-.78,.78-2.05,0-2.83Zm-15.41-7.59c0-2.76,2.24-5,5-5s5,2.24,5,5-2.24,5-5,5-5-2.24-5-5Z"/></svg>');
}
.ico-archives::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d5;}</style></defs><polygon class="cls-1" points="10 20.5 10 21 22 21 22 20 10 20 10 20.5"/><polygon class="cls-1" points="10 24.5 10 25 22 25 22 24 10 24 10 24.5"/><path class="cls-1" d="M26,8c-1.77,0-3.36,.78-4.46,2H5c-.55,0-1,.45-1,1V28h2V12h14.35c-.22,.63-.35,1.3-.35,2s.13,1.37,.35,2H10v1h10.81c1.04,1.79,2.97,3,5.19,3v8h2v-8.35c2.33-.82,4-3.04,4-5.65,0-3.31-2.69-6-6-6Zm-1.84,8.9c-.15,.15-.34,.22-.53,.22s-.38-.07-.53-.22c-.29-.29-.29-.77,0-1.06l2.15-2.15v-3.61s.01-.05,.02-.08c.04-.38,.35-.67,.73-.67s.7,.3,.73,.67c0,.03,.02,.05,.02,.08v3.92c0,.1-.02,.19-.06,.29-.04,.09-.09,.17-.16,.24l-.53,.53-1.84,1.84Z"/></svg>');
}
.ico-guide::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d5;}</style></defs><path class="cls-1" d="M18,6.5c-6.34,0-11.5,5.16-11.5,11.5s5.16,11.5,11.5,11.5,11.5-5.16,11.5-11.5-5.16-11.5-11.5-11.5Zm0,21c-5.24,0-9.5-4.26-9.5-9.5s4.26-9.5,9.5-9.5,9.5,4.26,9.5,9.5-4.26,9.5-9.5,9.5Z"/><path class="cls-1" d="M17.57,12.22c-.82,0-1.52,.14-2.59,.8-.51,.3-.93,.67-.93,1.1,0,.55,.35,.94,.82,.94,.29,0,.41-.09,.77-.41,.23-.2,.88-.67,1.82-.67,1.06,0,1.91,.59,1.91,1.58,0,.71-.16,.85-1.61,2.07-.33,.27-1.23,1.03-1.23,2.36,0,.38,.1,.87,.98,.87,.8,0,.9-.4,.96-.71,.13-.74,.17-.97,1.1-1.75,.91-.77,1.72-1.45,1.72-2.89,0-1.78-1.4-3.28-3.73-3.28Z"/><path class="cls-1" d="M17.5,21.78c-.67,0-1.21,.54-1.21,1.23s.54,1.22,1.21,1.22,1.23-.54,1.23-1.22-.56-1.23-1.23-1.23Z"/></svg>');
}
.ico-star::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m11.65 44 4.65-15.2L4 20h15.2L24 4l4.8 16H44l-12.3 8.8L36.35 44 24 34.6Z" fill="%23efef7f" /></svg>');
}
.ico-key1::before {
  width: min(9vw, 29px);
  height: min(9vw, 34px);
  align-items: flex-end;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%23fec00b;}</style></defs><path class="cls-1" d="M26.03,30.08H9.97c-1.59,0-2.89-1.3-2.89-2.89v-9.87c0-1.59,1.3-2.89,2.89-2.89H26.03c1.59,0,2.89,1.3,2.89,2.89v9.87c0,1.59-1.3,2.89-2.89,2.89ZM9.97,16.43c-.49,0-.89,.4-.89,.89v9.87c0,.49,.4,.89,.89,.89H26.03c.49,0,.89-.4,.89-.89v-9.87c0-.49-.4-.89-.89-.89H9.97Z"/><path class="cls-1" d="M25.87,15.13h-2.5v-1.33c0-2.97-2.42-5.39-5.39-5.39s-5.39,2.42-5.39,5.39v1.27h-2.5v-1.27c0-4.35,3.54-7.89,7.89-7.89s7.89,3.54,7.89,7.89v1.33Z"/><path class="cls-1" d="M18.99,23.05v2.48h-1.85v-2.48c-.75-.35-1.28-1.11-1.28-1.99,0-1.22,.99-2.2,2.2-2.2s2.2,.99,2.2,2.2c0,.89-.53,1.64-1.28,1.99Z"/></svg>');
}
.ico-key2::before {
  width: min(9vw, 29px);
  height: min(9vw, 34px);
  align-items: flex-end;
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%238e8e8e;}</style></defs><path class="cls-1" d="M26.03,30.08H9.97c-1.59,0-2.89-1.3-2.89-2.89v-9.87c0-1.59,1.3-2.89,2.89-2.89H26.03c1.59,0,2.89,1.3,2.89,2.89v9.87c0,1.59-1.3,2.89-2.89,2.89ZM9.97,16.43c-.49,0-.89,.4-.89,.89v9.87c0,.49,.4,.89,.89,.89H26.03c.49,0,.89-.4,.89-.89v-9.87c0-.49-.4-.89-.89-.89H9.97Z"/><path class="cls-1" d="M25.87,15.13h-2.5v-1.33c0-2.97-2.42-5.39-5.39-5.39s-5.39,2.42-5.39,5.39v1.27h-2.5v-1.27c0-4.35,3.54-7.89,7.89-7.89s7.89,3.54,7.89,7.89v1.33Z"/><path class="cls-1" d="M18.99,23.05v2.48h-1.85v-2.48c-.75-.35-1.28-1.11-1.28-1.99,0-1.22,.99-2.2,2.2-2.2s2.2,.99,2.2,2.2c0,.89-.53,1.64-1.28,1.99Z"/></svg>');
}
.ico-key3::before {
  width: min(9vw, 29px);
  height: min(9vw, 34px);
  align-items: flex-end;
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471d6;}</style></defs><path class="cls-1" d="M26.03,30.08H9.97c-1.59,0-2.89-1.3-2.89-2.89v-9.87c0-1.59,1.3-2.89,2.89-2.89H26.03c1.59,0,2.89,1.3,2.89,2.89v9.87c0,1.59-1.3,2.89-2.89,2.89ZM9.97,16.43c-.49,0-.89,.4-.89,.89v9.87c0,.49,.4,.89,.89,.89H26.03c.49,0,.89-.4,.89-.89v-9.87c0-.49-.4-.89-.89-.89H9.97Z"/><path class="cls-1" d="M25.87,15.13h-2.5v-1.33c0-2.97-2.42-5.39-5.39-5.39s-5.39,2.42-5.39,5.39v1.27h-2.5v-1.27c0-4.35,3.54-7.89,7.89-7.89s7.89,3.54,7.89,7.89v1.33Z"/><path class="cls-1" d="M18.99,23.05v2.48h-1.85v-2.48c-.75-.35-1.28-1.11-1.28-1.99,0-1.22,.99-2.2,2.2-2.2s2.2,.99,2.2,2.2c0,.89-.53,1.64-1.28,1.99Z"/></svg>');
}
.ico-play::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M19.15 32.5 32.5 24l-13.35-8.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z" fill="%23fff"/></svg>');
}
.ico-triangle::before {
  width: min(5vw, 14px);
  height: min(5vw, 14px);
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="50" width="50"><polygon points="0,0 0,50 50,25"/></svg>');
}
.ico-print::before {
  width: min(5vw, 30px);
  height: min(5vw, 30px);
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1{fill:%233471cd;}</style></defs><path class="cls-1" d="M26.98,13.37h-1.62V7.08c0-.41-.34-.75-.75-.75H11.4c-.41,0-.75,.34-.75,.75v6.29h-1.63c-1.67,0-3.03,1.36-3.03,3.03v9.12c0,.55,.45,1,1,1h3.66v2.15c0,.41,.34,.75,.75,.75h13.2c.41,0,.75-.34,.75-.75v-2.15h3.65c.55,0,1-.45,1-1v-9.12c0-1.67-1.36-3.03-3.03-3.03Zm-3.12,13.15v1.4H12.15v-6.79h11.7v5.4Zm.75-6.9H11.4c-.41,0-.75,.34-.75,.75v4.15h-2.66v-8.12c0-.57,.46-1.03,1.03-1.03H26.98c.57,0,1.03,.46,1.03,1.03v8.12h-2.65v-4.15c0-.41-.34-.75-.75-.75ZM12.15,7.83h11.7v5.54H12.15V7.83Z"/><circle class="cls-1" cx="10.64" cy="17.65" r=".96"/><rect class="cls-1" x="13.7" y="22.78" width="8.61" height="1"/><polygon class="cls-1" points="13.7 25.52 13.7 26.26 22.31 26.26 22.31 25.52 22.31 25.26 13.7 25.26 13.7 25.52"/></svg>');
}
.ico-custom::before {
  width: min(9vw, 20px);
  height: min(9vw, 20px);
  margin-right: 0.25rem;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m19.4 44-1-6.3q-.95-.35-2-.95t-1.85-1.25l-5.9 2.7L4 30l5.4-3.95q-.1-.45-.125-1.025Q9.25 24.45 9.25 24q0-.45.025-1.025T9.4 21.95L4 18l4.65-8.2 5.9 2.7q.8-.65 1.85-1.25t2-.9l1-6.35h9.2l1 6.3q.95.35 2.025.925Q32.7 11.8 33.45 12.5l5.9-2.7L44 18l-5.4 3.85q.1.5.125 1.075.025.575.025 1.075t-.025 1.05q-.025.55-.125 1.05L44 30l-4.65 8.2-5.9-2.7q-.8.65-1.825 1.275-1.025.625-2.025.925l-1 6.3ZM24 30.5q2.7 0 4.6-1.9 1.9-1.9 1.9-4.6 0-2.7-1.9-4.6-1.9-1.9-4.6-1.9-2.7 0-4.6 1.9-1.9 1.9-1.9 4.6 0 2.7 1.9 4.6 1.9 1.9 4.6 1.9Zm0-3q-1.45 0-2.475-1.025Q20.5 25.45 20.5 24q0-1.45 1.025-2.475Q22.55 20.5 24 20.5q1.45 0 2.475 1.025Q27.5 22.55 27.5 24q0 1.45-1.025 2.475Q25.45 27.5 24 27.5Zm0-3.5Zm-2.2 17h4.4l.7-5.6q1.65-.4 3.125-1.25T32.7 32.1l5.3 2.3 2-3.6-4.7-3.45q.2-.85.325-1.675.125-.825.125-1.675 0-.85-.1-1.675-.1-.825-.35-1.675L40 17.2l-2-3.6-5.3 2.3q-1.15-1.3-2.6-2.175-1.45-.875-3.2-1.125L26.2 7h-4.4l-.7 5.6q-1.7.35-3.175 1.2-1.475.85-2.625 2.1L10 13.6l-2 3.6 4.7 3.45q-.2.85-.325 1.675-.125.825-.125 1.675 0 .85.125 1.675.125.825.325 1.675L8 30.8l2 3.6 5.3-2.3q1.2 1.2 2.675 2.05Q19.45 35 21.1 35.4Z"/></svg>');
}
.ico-trash::before {
  width: min(5vw, 1.5em);
  height: min(5vw, 1.5em);
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M13.05 42q-1.25 0-2.125-.875T10.05 39V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-16.6 24.2h3V14.75h-3Zm8.3 0h3V14.75h-3Zm-13.6-24.2V39Z"/></svg>');
}
.ico-mail::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm17-15.1L7 13.75V37h34V13.75Zm0-3L40.8 11H7.25ZM7 13.75V11v26Z" fill="%23fff" /></svg>');
}

a[href$=".pdf"]:not(.ico-pdf)::after,
.ico-pdf::after,
.ico-dl.ico-pdf::before {
  content: '';
/*   display: inline-block;
  width: min(9vw, 32px);
  height: min(9vw, 32px);
  margin-right: 0.75rem;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs><style>.cls-1,.cls-2{fill:%23fff;}.cls-3{fill:%23e16c67;}.cls-2,.cls-4{stroke:%233471cd;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.cls-4{fill:none;}</style></defs><polygon class="cls-2" points="42.79 39.82 19.62 39.82 19.62 8.18 35.42 8.18 42.79 15.87 42.79 39.82"/><rect class="cls-3" x="5.21" y="19.35" width="32.32" height="17.36" rx="2" ry="2"/><g><path class="cls-1" d="M11.39,29.72v3.46h-2.02v-9.81h3.83c.74,0,1.38,.14,1.94,.4s.99,.65,1.29,1.15,.45,1.06,.45,1.69c0,.96-.33,1.72-.99,2.27-.66,.55-1.57,.83-2.73,.83h-1.77Zm0-1.64h1.81c.53,0,.94-.13,1.22-.38s.42-.61,.42-1.08-.14-.87-.42-1.17c-.28-.3-.67-.45-1.17-.46h-1.85v3.08Z"/><path class="cls-1" d="M18.27,33.18v-9.81h3.02c.86,0,1.63,.19,2.31,.58s1.21,.94,1.59,1.66c.38,.72,.57,1.53,.57,2.44v.45c0,.91-.19,1.72-.56,2.43-.38,.71-.9,1.26-1.59,1.65s-1.45,.59-2.31,.59h-3.04Zm2.02-8.17v6.55h.98c.79,0,1.39-.26,1.81-.78s.63-1.26,.64-2.22v-.52c0-1-.21-1.75-.62-2.27-.41-.51-1.02-.77-1.81-.77h-1Z"/><path class="cls-1" d="M33.14,29.17h-3.88v4.01h-2.02v-9.81h6.39v1.64h-4.37v2.53h3.88v1.63Z"/></g><polyline class="cls-4" points="41.73 15.87 35.43 15.87 35.43 9.27"/></svg>'); */
}

a[href$=".pdf"]:not(.ico-pdf)::after {
/*   width: min(6vw, 28px);
  height: min(6vw, 28px);
  margin-top: -0.25rem; */
}
.ico-dl::after {
	width: min(9vw, 2.5rem);
	height: min(9vw, 2.5rem);
  background-size: cover;
  background-repeat: no-repeat;
	margin-left: 3px;
  margin-right: 0;
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m24 30-10-9.95h20Z" fill="%23ffffff"/></svg>');
}

/* topicpath
____________________________________________________ */
.topicpath {
  width: 100%;
  margin: 1rem 0 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.topicpath li {
  font-size: min(3vw, 12px);
  display: flex;
  align-items: center;
}
.topicpath li:not(:last-of-type)::after {
  content: '\00203a';
  padding: 0 0.5rem;
}
.topicpath li [itemprop] {
  color: rgba(var(--color00),0.75);
}
@media ( min-width: 641px ) {
  .topicpath {
    margin: 0 0 1.5rem 0;
  }
}


/* each btns
____________________________________________________ */
.btn-wrap {
  display: flex;
  text-align: center;
  padding: 1rem 0;
  flex-direction: row;
  position: relative;
  justify-content: space-around;
  align-items: center;
}
.article-list + .btn-wrap {
  padding-top: 2rem;
  padding-bottom: 3rem;
  margin-bottom: 2rem;
}

.btn-wrap .btn {
  color: rgba(var(--color00),1);
  background: rgba(var(--color08),1);
  border: 1px solid rgba(var(--color00),0.5);
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  flex: 0 1 48%;
  text-align: center;
  border-radius: 5px;
  line-height: 1.4;
  padding: 0.75rem min(3.5vw,2rem);
  margin: 0 1%;
  font-size: min(4vw, 16px);
  text-decoration: none;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
}
.btn-wrap .btn:only-child {
  width: -moz-fit-content;
  width: fit-content;
  flex: 0 0 auto;
  margin: 0 auto;
}
.sub .btn-wrap .btn:only-child {
  flex: 1 1 auto;
}

.btn-wrap.with-caption {
  flex-direction: column;
}
.btn-wrap .btn-caption {
  text-align: left;
  width: 100%;
  line-height: 1.4;
  padding: 0 0 0.5rem 0;
  flex: 0 1 auto;
  font-size: min(4vw, 16px);
}
.btn-wrap .btn-caption + .btn {
  flex: 1 1 auto;
  width: 100%;
  padding: 1.25rem 2rem;
}
@media ( min-width: 641px ) {
  .btn-wrap.with-caption {
    flex-direction: row;
    padding: inherit 1rem;
    justify-content: space-between;
  }
  .btn-wrap .btn-caption {
    text-align: left;
    width: -moz-fit-content;
    width: fit-content;
    flex: 0 1 50%;
    padding: 0 1rem 0 0;
  }
  .btn-wrap .btn-caption + .btn {
    flex: 0 1 48%;
  }
}

.btn.subsc {
  background-color: rgba(var(--color03),1) !important;
  color: rgba(var(--color08),1) !important;
  border: none;
}
.btn.submit {
  background: rgba(var(--color01),1) !important;
  color: rgba(var(--color08),1) !important;
  border: none;
  padding: 0.75rem 3rem;
}
.btn.logout {
  background: rgba(var(--color08),1) !important;
  border: none !important;
}
.btn.account {
  background: rgba(var(--color01),1) !important;
  color: rgba(var(--color08),1) !important;
  border: none;
}
.btn.offerappli {
  background-color: rgba(var(--color03),1);
  color: rgba(var(--color08),1);
  border: none;
}
.btn.offerlogin {
  color: rgba(var(--color00),1);
  background-color: rgba(var(--color08),1);
}
.btn.listmore {
  display: block;
  width: fit-content;
  color: rgba(var(--color00),0.9);
  padding: 1rem 4rem 0.75rem 4rem;
  margin: 0 auto;
}
.btn.appli {
  background: rgba(var(--color01),1);
  color: rgba(var(--color08),1);
  border: none;
}
.btn.cancel {
  background: rgba(var(--color00),0.4);
  color: rgba(var(--color08),1);
  border: none;
}
.btn:hover {
  opacity: 0.85;
}

/* wrap thumb img
____________________________________________________ */
.thumb {
  position: relative;
  overflow: hidden;
  display: block;
}

.thumb2 {
  position: relative;
  overflow: hidden;
  display: block;
}

.thumb.loading::after {
  content: '';
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 0;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path opacity=".25" fill="%230075c1" d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0m0 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4"/><path fill="%230075c1" d="M16 0c8.8 0 16 7.2 16 16h-4c0-6.6-5.4-12-12-12V0z"></path></svg>');
  animation: loading 1.5s linear 0s infinite forwards;
}
@keyframes loading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.thumb > video {
  width: 100%;
  position: relative;
  z-index: 1;
}
.thumb .vidposter {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 10;
  opacity: 1;
}
.thumb .vidposter.vidplay {
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s ease;
}
.thumb .vidposter::before {
  content: '';
  cursor: pointer;
  width: min(10vw, 40%);
  height: min(10vw, 40%);
  z-index: 1000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  display: inline-flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M19.15 32.5 32.5 24l-13.35-8.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z" fill="%23fff"/></svg>');
}
.thumb > iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 4 / 3;
  position: relative;
  z-index: 1;
}
.thumb > img {
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  left: 50%;
  top: 20%;
  transform: translate3d(-50%, -20%, 0);
}

.thumb2 > img {
  position: absolute;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  left: 50%;
  top: 20%;
  transform: translate3d(-50%, -20%, 0);
}

[data-mov="1"] .thumb::before {
  content: '';
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  top: 0;
  z-index: 3;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54"><defs><style>.cls-1{fill:%23fff;stroke-width:5px;}.cls-1,.cls-2,.cls-3,.cls-4{stroke-linecap:round;stroke-linejoin:round;}.cls-1,.cls-4{stroke:%23fff;}.cls-2{fill:%233471d5;stroke-width:2px;}.cls-2,.cls-3{stroke:%233471d5;}.cls-3{fill:rgba(255,255,255,.7);stroke-width:2.5px;}.cls-4{fill:none;stroke-width:6px;}</style></defs><circle class="cls-4" cx="27" cy="27" r="19.13"/><circle class="cls-3" cx="27" cy="27" r="19.13"/><path class="cls-1" d="M34.87,27l-12.52,7.23v-14.46l12.52,7.23Z"/><path class="cls-2" d="M34.87,27l-12.52,7.23v-14.46l12.52,7.23Z"/></svg>');
}


/* for original-aspect size img content. max width for full
____________________________________________________ */
.has-fullimg {
  margin-bottom: 2rem;
}
.has-fullimg .thumb {
  margin-bottom: 0.75rem;
  aspect-ratio: auto !important;
}
.has-fullimg .thumb > img {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
  width: auto;
  max-width: 100%;
  height: auto;
  min-height: auto;
  margin: 0 auto;
  display: block;
  user-select: none;
  -webkit-touch-callout: none;
}
.has-fullimg figcaption {
  font-size: min(3vw, 13px);
  width: 80%;
  margin: auto;
}


/* ONELINE NEWS
____________________________________________________ */
.oneline-news {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: rgba(var(--color02),1);
  padding: 0.5rem 1.25rem;
  line-height: 1;
  border-radius: 3px;
}
.oneline-news span {
  font-size: min(3.5vw, 11px);
  font-weight: 700;
  color: rgba(var(--color01),1);
  padding: 0.5rem 0;
}
.oneline-news p {
  font-size: min(3.5vw, 13px);
  font-weight: 700;
  color: rgba(var(--color01),1);
  padding: 0.5rem 0 0.5rem 0.75rem;
  letter-spacing: 0;
}
.oneline-news.covid19 {
  background: rgba(var(--color01),1);
  margin-bottom: 1.75em;
  border-radius: 5px;
}
.oneline-news.covid19 span {
  font-size: min(3.5vw, 13px);
  color: rgba(var(--color08),1);
  white-space: nowrap;
  padding: 0.25rem 1rem 0.25rem 0;
  border-right: 1px solid rgba(var(--color08),1);
  line-height: 1.4;
}
.oneline-news.covid19 p {
  padding: 0.25rem 0 0.25rem 1rem;
  color: rgba(var(--color08),1);
  line-height: 1.4;
}
.oneline-news > * {
  width: 100%;
  display: flex;
  align-items: center;
}
.oneline-news > *:only-child {
  width: 100%;
}


/* NEWS TICKER
____________________________________________________ */
.ticker-wrap {
  background: rgba(var(--color01),1);
  line-height: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0 !important;
}
.ticker-wrap .tic-label {
  flex: 0 0 auto;
  width: 100%;
}
.ticker-wrap .tic-label a {
  color: rgba(var(--color08),1);
  padding: 0.5rem 0.75rem 0.5rem 1rem;
}
.ticker-wrap .tic-links {
  flex-grow: 1;
  border-radius: 3px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding-right: 5rem;
  overflow: hidden;
  max-height: 2em;
}
.ticker-wrap .tic-links a {
  padding: 0.5em 1em;
  color: rgba(var(--color01),1);
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  background: rgba(var(--color08),1);
}
.ticker-wrap .spin_wrap {
  display: flex;
  align-items: center;
  position: absolute;
  width: 5rem;
  height: 100%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.ticker-wrap .spin {
  position: absolute;
  height: min(5vw,21px);
  cursor: pointer;
  line-height: 1;
  padding: 0;
  background: none;
  outline: none;
  border: none;
}
.ticker-wrap .spin::before {
  content: '';
  display: block;
  width: min(7vw, 25px);
  height: min(7vw, 16px);
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.2941 11.3277"><path d="M.3,8.9022,6.409.6186A1.5221,1.5221,0,0,1,8.8571.6161L14.9925,8.9a1.5221,1.5221,0,0,1-1.2231,2.428H1.5248A1.5221,1.5221,0,0,1,.3,8.9022Z" fill="%23fff"/></svg>');
}
.ticker-wrap .spin > svg {
  display: none;
}
.ticker-wrap .spin.up {
  right: 0.35rem;
}
.ticker-wrap .spin.dn {
  right: 2.5rem;
}
.ticker-wrap .spin.dn::before {
  transform: scale(1,-1);
}
@media ( min-width: 641px ) {
  .ticker-wrap {
    flex-wrap: nowrap;
  }
  .ticker-wrap .tic-label {
    width: auto;
  }
}


/* REPLACE LIST
____________________________________________________ */
.cat-tabs {
  padding: 0;
  background: rgba(var(--color02),1);
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 0.5rem;
}
.cat-tabs ul {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: flex-start;
  width: 100%;
}
.cat-tabs ul li {
  color: rgba(var(--color01),1);
  background: rgba(var(--color02),1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: all 0.25s ease;
  font-size: min(3.5vw, 13px);
  padding: 1.25em 1.5em;
}
.cat-tabs ul li.current {
  color: rgba(var(--color08),1);
  background: rgba(var(--color01),1);
}
.cat-tabs ul li a {
  color: rgba(var(--color01),1);
}
.cat-tabs ul li.current a {
  color: rgba(var(--color08),1);
}
@media ( min-width: 641px ) {
  .cat-tabs {
    padding: 0;
  }
  .cat-tabs ul {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}
@media ( min-width: 1281px ) {
  .cat-tabs .splide__track {
    overflow: visible;
  }
}
.cat-tabs .splide__arrow {
  position: absolute;
  height: min(5vw,21px);
  cursor: pointer;
  line-height: 1;
  padding: 0;
  background: none;
  outline: none;
  border: none;
}
.cat-tabs .splide__arrow[disabled] {
  display: none;
}
.cat-tabs .splide__arrow > svg {
  display: none;
}
.cat-tabs .splide__arrow--prev,
.cat-tabs .splide__arrow--next {
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--color02),0.75) !important;
}
.cat-tabs .splide__arrow--prev {
  left: 0;
}
.cat-tabs .splide__arrow--next {
  right: 0;
}
.cat-tabs .splide__arrow::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 65%;
}
.cat-tabs .splide__arrow--prev::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M28.05 37.2 14.8 23.95 28.05 10.7l3.35 3.35-9.9 9.9 9.9 9.9Z" fill="%23555"/></svg>');
}
.cat-tabs .splide__arrow--next::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%23555"/></svg>');
}



.js-replace-list > * {
  display: none !important;
  pointer-events: none;
  opacity: 0;
  transform: translateY(0.5rem);
}
.js-replace-list *.current {
  display: flex !important;
  flex-wrap: wrap;
  pointer-events: auto;
  animation: showReplace 1s ease 0.15s 1 normal forwards;
}
@keyframes showReplace {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.region .article-list li .detail .pubmeta .meta-cat {
  /* display: none; */
}


/* LIST SERVICE
____________________________________________________ */
.service-list li {
  margin: 2rem 0 !important;
}
.service-list li .thumb {
  aspect-ratio: 2 / 1 !important;
}




/* LIST ARTICLE sns with article header
____________________________________________________ */
.meta-socials {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  order: 10;
}
.meta-socials > * {
  min-width: 2em;
  cursor: pointer;
}
.meta-socials .sns {
  width: 2em;
  margin-left: 1rem;
}
.meta-socials .sns img {
  width: 100%;
}
.withTags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 0;
  line-height: 1;
}
.withTags a {
  font-size: min(3vw, 12px);
  letter-spacing: 0;
  color: rgba(var(--color00),0.8);
  border-radius: 2em;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  margin-right: 0.55rem;
  display: inline-block;
  position: relative;
}
.withTags a:not(:last-of-type)::after {
  content: '\00ff5c';
  position: absolute;
  left: 100%;
}
.js-filter-wrap ul li {
  transition: transform .2s ease-out, opacity .2s linear;
}
.js-filter-wrap ul li.hide {
  opacity: 0;
  transform: translate3d(0,10px,0);
  backface-visibility: hidden;
}


/* GALLERY
_____________________________________________ */
.gallery {
  width: 100%;
  overflow: hidden;
}
.gallery::after {
  content: '';
  display: table;
  clear: both;
}
.gallery li {
  width: calc( 100% / 5 - 2% );
  margin-right: 2%;
  margin-bottom: 2%;
  float: left;
}
.gallery li img {
  width: 100%;
}

.gallery-wrap {
  width: 100%;
  overflow: hidden;
  float: left;
  margin: 5px 2rem 1rem 0;
  background: rgba(var(--color00),0.05);
  border: 1px solid rgba(var(--color00),0.3);
  padding: 0.5rem;
  border-radius: 10px;
  position: relative;
}
.gallery-wrap .current-photo .thumb {
	aspect-ratio: 4 / 3;
	position: relative;
	overflow: hidden;
	display: block;
  /* width: calc( 100% - (48px * 2) ); */
  margin: 0 auto 1rem auto;
}
.gallery-wrap .current-photo .thumb img {
  position: absolute;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  user-select: none;
  -webkit-touch-callout: none;
}
.gallery-wrap .current-photo figcaption {
  font-size: min(4.5vw, 13px);
  text-align: left;
  margin-bottom: 1rem;
}
.gallery-wrap .gallery-thumb {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
.gallery-wrap .gallery-thumb li {
  flex: 0 0 calc(100% / 5);
  display: block;
  cursor: pointer;
  background: rgba(var(--color00),1);
}
.gallery-wrap .gallery-thumb li a {
  display: block;
}
.gallery-wrap .gallery-thumb li img {
  width: 100%;
  opacity: 0.5;
}
.gallery-wrap .gallery-thumb li:hover img,
.gallery-wrap .gallery-thumb li.current img {
  opacity: 1;
}

.gallery-wrap .current-photo .prev,
.gallery-wrap .current-photo .next {
  position: absolute;
  display: inline-block;
  width: 48px;
  height: 48px;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  outline: none;
  cursor: pointer;
  overflow: hidden;
}
.gallery-wrap .current-photo .prev {
  right: calc( 100% - 48px );
  left: auto;
  display: none;
}
.gallery-wrap .current-photo .next {
  left: calc( 100% - 48px );
  right: auto;
  display: none;
}
.gallery-wrap .current-photo .prev::before,
.gallery-wrap .current-photo .next::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.gallery-wrap .current-photo .prev::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M28.05 37.2 14.8 23.95 28.05 10.7l3.35 3.35-9.9 9.9 9.9 9.9Z" fill="%23555"/></svg>');
}
.gallery-wrap .current-photo .next::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%23555"/></svg>');
}


/* MODAL GALLERY
_____________________________________________ */
.knockOut {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900000;
  background: rgba(0,0,0,.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.np-modal {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 999999;
  background: transparent;
  text-align: left;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  max-height: 70%;
  transition: opacity .5s ease 0s;
}

.np-modal .modalClose {
  position: absolute;
  bottom: 100%;
  right: 0;
  text-align: center;
  cursor: pointer;
  width: 48px;
  height: 48px;
}
.np-modal .modalClose::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z" fill="%23fff" /></svg>') no-repeat center;
  background-size: contain;
}
.np-modal.mopen {
  opacity: 1;
  pointer-events: auto;
}
.np-modal .modalInner {
  margin: 0;
  padding: 0;
}
@media ( min-width: 834px ) {
  .np-modal {
    width: auto;
    height: auto;
  }
}
@media ( min-width: 1281px ) {
  .np-modal {
    max-width: 980px;
  }
}

.np-modal .gallery-photo li {
  display: flex;
  justify-content: center;
  max-height: calc((100vh - 20vh) * 0.75);
}
.np-modal .gallery-photo li img {
  width: 100%;
  object-fit: contain;
  filter: drop-shadow( 0 0 0.5rem rgba(0,0,0,0.75) );
  margin-bottom: 3.5rem;
}
.np-modal .gallery-photo li p {
  position: absolute;
  z-index: 100;
  font-size: min(3vw, 16px);
  top: calc(100% - 3rem);
  width: 100%;
  text-align: center;
  line-height: 1.4;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: rgba(var(--color08),1);
}

.np-modal .gallery-thumb {
  width: 100%;
}
@media ( min-width: 641px ) {
  .np-modal .gallery-thumb {
    max-width: 100%;
    margin: 0 auto;
  }
}
@media ( min-width: 1281px ) {
  .np-modal .gallery-thumb {
    max-width: 80%;
    margin: 0 auto;
  }
}
.np-modal .gallery-thumb li {
  display: block;
  cursor: pointer;
}
.np-modal .gallery-thumb li img {
  width: 100%;
  opacity: 0.65;
  border: 2px solid transparent;
}
.np-modal .gallery-thumb li:hover img,
.np-modal .gallery-thumb .is-active img {
  opacity: 1;
  border: 2px solid rgba(var(--color01),1);
}

.np-modal .gallery-photo .splide-wrapper {
  position: relative;
}
.np-modal .gallery-photo .splide__arrow {
  position: absolute;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  background: none;
  outline: none;
  border: none;
}
.np-modal .gallery-photo .splide__arrow[disabled] {
  display: none;
}
.np-modal .gallery-photo .splide__arrow > svg {
  display: none;
}
.np-modal .gallery-photo .splide__arrow--prev,
.np-modal .gallery-photo .splide__arrow--next {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  margin: auto;
}
.np-modal .gallery-photo .splide__arrow--prev {
  right: calc(100% + 2.4rem);
}
.np-modal .gallery-photo .splide__arrow--next {
  left: calc(100% + 2.4rem);
}

.np-modal .gallery-photo .splide__arrow::before {
  content: '';
  display: inline-block;
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
}
.np-modal .gallery-photo .splide__arrow--prev::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M28.05 37.2 14.8 23.95 28.05 10.7l3.35 3.35-9.9 9.9 9.9 9.9Z" fill="%23fff"/></svg>');
}
.np-modal .gallery-photo .splide__arrow--next::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%23fff"/></svg>');
}


/* plain document
_____________________________________________ */
.docs-header {
	padding: 1rem 0 1.5rem 0;
}
.docs-header .header-note {
	font-size: min(4vw, 16px);
	line-height: 1.6;
}

.docs-footer {
	padding: 2rem 0 3rem 0;
}
.footer-note {
  width: fit-content;
  margin: 0 auto;
}
.footer-note li {
  font-size: min(4vw, 14px);
  padding: 0.25em 0;
}


/* FUNCTION
_____________________________________________ */
.l { text-align: left; justify-content: flex-start; }
.c { text-align: center; justify-content: center; }
.r { text-align: right; justify-content: flex-end; }

.px12 { font-size: min(3vw, 12px)!important; }
.px14 { font-size: min(4vw, 14px)!important; }
.px16 { font-size: min(4vw, 16px)!important; }
.px18 { font-size: min(4.1vw, 18px)!important; }
.px20 { font-size: min(4.2vw, 20px)!important; }
.px22 { font-size: min(4.3vw, 22px)!important; }
.px24 { font-size: min(4.4vw, 24px)!important; }
.px28 { font-size: min(4.6vw, 28px)!important; }


._sp { display: block; }
._tb { display: none; }
._pc { display: none; }
@media ( min-width: 641px ) {
  ._sp { display: none !important; }
}
@media ( min-width: 641px ) and ( max-width: 1280px ) {
  ._tb { display: block; }
  ._pc { display: block; }
}
@media ( min-width: 1281px ) {
  ._tb { display: none; }
  ._pc { display: block; }
}


[class*="login"] .hideloggedin {
  display: none !important;
}

@media print {
  .overhead,
  .site-function,
  .drawer-btn,
  .splide.is-initialized, 
  .splide.is-rendered,
  .topicpath li,
  .meta-socials,
  .sec>.sec,
  [id^="sm_box_"],
  [id^="google_ads_iframe_"],
  [id^="browsi_adWrapper_ai_"],
  [id^="browsi_adContainer_ai_"],
  .bottom_fixed_ad,
  .sm-unit .svelte-xurmir,
  .sec>.sec,
  .ad-wrap,
  .column-wrap .sub,
  .global-footer,
  .overhead {
      display: none!important
  }

  header {
      position: absolute!important;
      top: 0!important;
      overflow: hidden!important;
  }

  .article-media-wrap {
      display: block!important;
      width:45%!important;
      float: left!important;
      margin-right: 1rem!important;
      margin-bottom: 1rem!important;
  }

  .article-header,
  .article-body {
      position: initial!important;
  }  

  .article-body p {
      margin-bottom: 1rem!important;
  }

  .column-wrap {
      position: absolute!important;
      top: 20px!important;
  }

  .siteid a {
      width: 200px!important;
      margin-bottom: 5px!important;
  }

  .gallery-within-article .current-photo figcaption {
    width: 100%!important;
  }
}